import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionGrid from "../components/section/SectionGrid"
import SectionHeading from "../components/section/SectionHeading"
import SectionInfo from "../components/section/SectionInfo"
import SectionParagraph from "../components/section/SectionParagraph"
import SectionLink from "../components/section/SectionLink"

const NotFound = () => {
  return (
    <>
      <Seo
        title={"Seite nicht gefunden | Avalum"}
        description={"Die angeforderte Seite existiert nicht."}
      />
      <Section
        sectionClassName={"h-sh"}
        sectionContentClassName={"flex h-full items-center text-center"}
      >
        <SectionGrid>
          <SectionInfo>
            <SectionHeading>Seite nicht gefunden</SectionHeading>
            <SectionParagraph>
              Die angeforderte Seite existiert nicht
            </SectionParagraph>
            <SectionLink sx={"mx-auto"} to={"/"} title={"Zu Startseite"} />
          </SectionInfo>
        </SectionGrid>
      </Section>
    </>
  )
}

export default NotFound
